<template lang="pug">
main(@click="closeGradeModal($event)")
  .header
    .container
      //- JR logo
      router-link.jr-logo(:to="{name:'home'}")
        img(:src="require('@/assets/event/trial/jr-logo.svg')", alt="WUWOW JR Logo")
      a.contact(href="tel:tel:0800885315") 0800&ensp;885&ensp;315&ensp;幫幫我&ensp;上英文&ensp;|&ensp;撥打專線，立即有專人為您服務

  .form-upper
    //- img.form-visual.mobile(:src="require('@/assets/event/trial/form-visual-mobile.png')", alt="表單主視覺")
    //- img.form-visual.tablet(:src="require('@/assets/event/trial/form-visual-tablet.png')", alt="表單主視覺")
    .form-visual
      img.visual-background(:src="require('@/assets/event/trial/bg-form-visual.png')", alt="表單主視覺背景")
      img.spark.spark-small(:src="require('@/assets/event/trial/spark-small.png')", alt="星型裝飾")
      img.spark.spark-medium(:src="require('@/assets/event/trial/spark-medium.png')", alt="星型裝飾")
      img.spark.spark-large(:src="require('@/assets/event/trial/spark-large.png')", alt="星型裝飾")

      button.visual-a(data-scroll,@click="openReasonModal('a')")
        img.sm(:src="require('@/assets/event/trial/visual-a-sm.png')", alt="特色a")
        img.lg(:src="require('@/assets/event/trial/visual-a-lg.png')", alt="特色a")
      button.visual-b(data-scroll,@click="openReasonModal('b')")
        img.sm(:src="require('@/assets/event/trial/visual-b-sm.png')", alt="特色b")
        img.lg(:src="require('@/assets/event/trial/visual-b-lg.png')", alt="特色b")
      button.visual-c(data-scroll,@click="openReasonModal('c')")
        img.sm(:src="require('@/assets/event/trial/visual-c-sm.png')", alt="特色b")
        img.lg(:src="require('@/assets/event/trial/visual-c-lg.png')", alt="特色b")
      button.visual-d(data-scroll,@click="openReasonModal('d')")
        img.sm(:src="require('@/assets/event/trial/visual-d-sm.png')", alt="特色b")
        img.lg(:src="require('@/assets/event/trial/visual-d-lg.png')", alt="特色b")

    .form-area
      .title-area
        .main-title
          p(data-scroll)  孩子愛上
            span.highlight 學英語
          img(:src="require('@/assets/event/trial/title-abc.svg')")

        .subtitle
          span(data-scroll) &ensp;學的不只是語言，
          span(data-scroll) 更是未來
            span.highlight &ensp;無限可能
        .text-center.mt-2
          img(class="inline-block" :src="require('@/assets/event/cross-industry/brand_Logos.png')")

      .form-banner(data-scroll)
        //- img.mobile(:src="require('@/assets/event/cross-industry/form-banner-mobile.png')")
        //- img.tablet(:src="require('@/assets/event/cross-industry/form-banner-tablet.png')")
        //- img.desktop(:src="require('@/assets/event/cross-industry/form-banner-desktop.png')")

        //- 2023周年慶 banner圖片替換
        img.mobile.mobile-banner(:src="mobileBannerSrc")
        img.tablet.tablet-banner(:src="tabletBannerSrc")
        img.desktop.desktop-banner(:src="desktopBannerSrc")

      .form-card(data-scroll)
        .form-input
          .icon: img(:src="require('@/assets/event/trial/member-orange.svg')")
          input(v-model="formUpper.parentName",type="text",placeholder="家長姓名（必填）")

        .form-input
          .icon: img(:src="require('@/assets/event/trial/phone-orange.svg')")
          input(v-model="formUpper.parentPhone",type="text",placeholder="家長電話（必填）")
        .form-input
          .icon: img(,:src="require('@/assets/event/trial/mail-orange.svg')")
          input(v-model="formUpper.parentEmail",type="text",placeholder="家長email（必填）")

        .form-privacy
          input#privacy-upper(v-model="formUpper.privacyPolicyCheck",type="checkbox")
          label(for="privacy-upper")
            img(:src="require('@/assets/event/trial/check.svg')")
          span 我同意 WUWOW 線上英文&ensp;
            router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策

        button.form-btn(@click="submitForm(formUpper)") 免費搶先預約

        .form-divider
          img(:src="require('@/assets/event/trial/divider-left.svg')")
          span 好禮領取
          img(:src="require('@/assets/event/trial/divider-right.svg')")
        .gift-area.mb-2.mb-xl-4
          button.gift.w-100.ml-0(@click="doc.content = docList[0]; doc.isOpen = true")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            |  兒童線上英文家教課1堂
        .gift-area
          button.gift(type="button" @click="galleryShow = true")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            | 劍橋兒童英檢單字書
          button.gift(@click="doc.content = docList[1]; doc.isOpen = true" type="button")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            | 孩子英文能力報告

  .question

    .question-container
      img.question-title.mobile(:src="require('@/assets/event/trial/question-title-mobile.png')", alt="提問標題")
      img.question-title.tablet(:src="require('@/assets/event/trial/question-title-tablet.png')", alt="提問標題")
      img.question-title.desktop(:src="require('@/assets/event/trial/question-title-desktop.png')", alt="提問標題")

      .saying-container
        .question-visual(data-scroll)
          img.mobile(:src="require('@/assets/event/trial/question-visual-mobile.png')", alt="提問主視覺")
          img.tablet(:src="require('@/assets/event/trial/question-visual-tablet.png')", alt="提問主視覺")
          img.desktop( :src="require('@/assets/event/trial/question-visual-desktop.png')", alt="提問主視覺")

        .saying
          img.globe(:src="require('@/assets/event/trial/globe.png')")
          .famous(data-scroll)
            img.link(:src="require('@/assets/event/trial/link.svg')")
            img.avatar(:src="require('@/assets/event/trial/famous-1.png')")
            .bubble
              img.quotation-left(:src="require('@/assets/event/trial/quotation-left.svg')")
              img.quotation-right(:src="require('@/assets/event/trial/quotation-right.svg')")
              span 全球知名語言發展專家—派翠西亞．庫兒博士 (Patricia K. Kuhl, Ph. D.)的研究指出：「0-7歲是人類語言學習的關鍵期，且此時的兒童有能力同時學習兩種語言。」

          .famous(data-scroll)
            img.link(:src="require('@/assets/event/trial/link.svg')")
            img.avatar(:src="require('@/assets/event/trial/famous-2.png')")
            .bubble
              img.quotation-left(:src="require('@/assets/event/trial/quotation-left.svg')")
              img.quotation-right(:src="require('@/assets/event/trial/quotation-right.svg')")
              span 美國神經學家戴蒙（Marian C. Diamond）及霍普森（Janet Hopson）強調：「人類在 12 歲以前腦部的成長發育相當快速，通常能達到成人大腦重量的 90％。」

          .famous(data-scroll)
            img.link(:src="require('@/assets/event/trial/link.svg')")
            img.avatar(:src="require('@/assets/event/trial/famous-3.png')")
            .bubble
              img.quotation-left(:src="require('@/assets/event/trial/quotation-left.svg')")
              img.quotation-right(:src="require('@/assets/event/trial/quotation-right.svg')")
              span 美國深具影響力的認知心理學學術期刊〈Cognition〉刊載：「10 歲前為語言學習關鍵期，此期間加強練習外語，有機會達母語程度！」

      .summary
        img.quotation-left(:src="require('@/assets/event/trial/quotation-left.svg')")
        img.quotation-right(:src="require('@/assets/event/trial/quotation-right.svg')")
        p(data-scroll) 越早開始學習，越能輕鬆無壓習得英文能力。
        p(data-scroll)
          | 讓英文不再是第二外語，
          br.mobile
          | 而是寶貝們的「
          span.highlight 母語
          | 」！

  .benefit
    img.mobile.benefit-title(:src="require('@/assets/event/trial/benefit-title-mobile.png')")
    img.tablet.benefit-title(:src="require('@/assets/event/trial/benefit-title-tablet.png')")
    img.desktop.benefit-title(:src="require('@/assets/event/trial/benefit-title-desktop.png')")

    .card-area
      .card-1.benefit-card(data-scroll)
        img.mobile(:src="require('@/assets/event/trial/benefit-card-1-mobile.png')")
        img.tablet(:src="require('@/assets/event/trial/benefit-card-1-tablet.png')")
        img.desktop(:src="require('@/assets/event/trial/benefit-card-1-desktop.png')")
      .card-2.benefit-card(data-scroll)
        img.mobile(:src="require('@/assets/event/trial/benefit-card-2-mobile.png')")
        img.tablet(:src="require('@/assets/event/trial/benefit-card-2-tablet.png')")
        img.desktop(:src="require('@/assets/event/trial/benefit-card-2-desktop.png')")
      .card-3.benefit-card(data-scroll)
        img.mobile(:src="require('@/assets/event/trial/benefit-card-3-mobile.png')")
        img.tablet(:src="require('@/assets/event/trial/benefit-card-3-tablet.png')")
        img.desktop(:src="require('@/assets/event/trial/benefit-card-3-desktop.png')")

    img.benefit-abcd(:src="require('@/assets/event/trial/benefit-abcd.svg')")

    //- 樹枝
    img.bud-left(:src="require('@/assets/event/trial/bud-left.svg')")
    img.bud-right(:src="require('@/assets/event/trial/bud-right.svg')")

    //- 底部圓弧
    img.benefit-curve.mobile(:src="require('@/assets/event/trial/benefit-curve-mobile.svg')")
    img.benefit-curve.tablet(:src="require('@/assets/event/trial/benefit-curve-tablet.svg')")
    img.benefit-curve.desktop(:src="require('@/assets/event/trial/benefit-curve-desktop.svg')")

  .learning
    h2.learning-title(data-scroll)
      | 既然英文這麼重要，
      br
      | 父母該怎麼幫孩子打造
      |
      br.d-md-none.d-block
      img(:src="require('@/assets/event/trial/learnEnglishTitle.png')")
      |
      | 呢？
    .learning-banner(data-scroll)
      .d-xl-block.d-none
        img(:src="require('@/assets/event/trial/englishTeacher-xl.png')")
      .d-xl-none.d-md-block.d-none
        img(:src="require('@/assets/event/trial/englishTeacher-lg.png')")
      .d-md-none
        img(:src="require('@/assets/event/trial/englishTeacher-sm.png')")
    .learning-main
      .d-lg-block.d-none
        img.learning-main_picXl(:src="require('@/assets/event/trial/learnEnglishMain.png')")
      .d-lg-none.d-sm-block.d-none
        img(:src="require('@/assets/event/trial/learnEnglishMain-tablet.png')")
      .d-sm-none.d-flex.flex-column.justify-content-center.align-items-center
        img(:src="require('@/assets/event/trial/learnEnglishMain-mobile.png')")

  .onlinePlatform
    .text-center.onlinePlatform-title
      img(:src="require('@/assets/event/trial/onlinePlatformTitle.png')")
    .text-center.onlinePlatform-table(data-scroll)
      .d-lg-block.d-none.text-center
        img(:src="require('@/assets/event/trial/onlinePlatformTable-lg.svg')")
      .d-lg-none.text-center
        img(:src="require('@/assets/event/trial/onlinePlatformTable-sm.svg')")

  .learnModel
    .learnModel-container
      .learnModel-wrap.d-flex.align-items-center.justify-content-center.flex-xl-nowrap.flex-wrap
        .learnModel-students(data-scroll)
          img(:src="require('@/assets/event/trial/learnModel-Pic.png')")
          img(:src="require('@/assets/event/trial/learnModel-Pic1.png')")
          img(:src="require('@/assets/event/trial/learnModel-Pic2.png')")
        .learnModel-main
          .ball-small(data-scroll)
            img.mobile(:src="require('@/assets/event/trial/learnModel-ball-sm-mobile.png')")
            img.tablet(:src="require('@/assets/event/trial/learnModel-ball-sm-desktop.png')")
            img.desktop(:src="require('@/assets/event/trial/learnModel-ball-sm-desktop.png')")
          .ball-large(data-scroll)
            img.mobile(:src="require('@/assets/event/trial/learnModel-ball-lg-mobile.png')")
            img.tablet(:src="require('@/assets/event/trial/learnModel-ball-lg-desktop.png')")
            img.desktop(:src="require('@/assets/event/trial/learnModel-ball-lg-desktop.png')")
          h2.learnModel-title(data-scroll)
            span.text-major 線上學習
            | 模式，已成主流！
          h2.learnModel-title(data-scroll)
            | 後疫情時代
            span.text-major 教育部
            | 也這麼做！
          p(data-scroll).text-center.text-xl-left
            | 以「班班有網路，生生用平板」為口號，
            br.mobile
            | 政府提升學校網路、提供載具，
            br.mobile
            | 並充實數位內容，線上英文學習已然成為趨勢。

  .learnReason
    .learnReason-container
      .text-center
        button.btn.btn-xl.learnReason-order(@click="scrollForm('.form-lower')",type="button") 免費試聽，立即預約
      .learnReason-title.text-center
        h2.mb-5
          | 首選
          span.mx-1.learnReason-scondary WUWOW Jr
          br.d-xl-none
          | 兒童線上英文的理由
      ul.learnReason-main
        li(data-scroll)
          .learnReason-card
            .d-flex.align-items-start.d-md-block.text-md-center
              img(:src="require('@/assets/event/trial/learnReason1.png')")
              div
                h3.learnReason-scondary 25 分鐘 1 對 1 家教
                img.dec(:src="require('@/assets/event/trial/dec.png')")
                p
                  | 短時間專注學習，
                  br
                  | 家教型VIP精緻學
                  br
                  | 寶貝進步超有感！
            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S
        li(data-scroll)
          .learnReason-card
            .d-flex.align-items-start.d-md-block.text-md-center
              img(:src="require('@/assets/event/trial/learnReason2.png')")
              div
                h3.learnReason-scondary 課綱明確 分級嚴謹
                img.dec(:src="require('@/assets/event/trial/dec.png')")
                p
                  | 以明確課綱對應國際標準分級，
                  br
                  | 漸進提升孩子的英文，
                  br
                  | 與國際接軌。

            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S
        li(data-scroll)
          .learnReason-card
            .d-flex.align-items-start.d-md-block.text-md-center
              img(:src="require('@/assets/event/trial/learnReason3.png')")
              div
                h3.learnReason-scondary 主題沈浸式 互動教學
                img.dec(:src="require('@/assets/event/trial/dec.png')")
                p
                  | 以日常情境激發學習熱情，
                  br
                  | 在互動中同步練習
                  br
                  | 聽、說、讀、寫，
                  br
                  | 全方位沈浸式英文學習。
            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S
        li(data-scroll)
          .learnReason-card
            .d-flex.align-items-start.d-md-block.text-md-center
              img(:src="require('@/assets/event/trial/learnReason4.png')")
              div
                h3.learnReason-scondary 嚴選師資 汰弱留強
                img.dec(:src="require('@/assets/event/trial/dec.png')")
                p
                  | 師資皆有英文教學認證
                  br
                  | 及兒美教學經驗，
                  br
                  | 輔以定期回訓汰留，
                  br
                  | 維持最佳教學品質。
            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S
    .learnReason-bannerContainer
      .learnReason-banner
        h2.mb-4
          | 想知道更多父母選擇
          br.d-block.d-md-none
          span.mx-1.learnReason-scondary  WUWOW Jr
          | 的理由？
        .learnReason-arrows(data-scroll)
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
        .text-center
          label.btn.btn-xl.learnReason-more(for="btn-check") 點我看更多
        input.btn-check.d-none(type="checkbox" id="btn-check" autocomplete="off")
        .learnReason-table
          .learnReason-tableWrap
            .d-none.d-xl-block
              img(:src="require('@/assets/event/trial/learnReason-tableXl.png')")
            .d-xl-none
              img(:src="require('@/assets/event/trial/learnReason-tableSm.png')")

  .grade
    .grade-container
      h2.grade-title
        | WUWOW 獨創
        span.mx-1.grade-scondary 0~6 等分級制度
        | ，
        br
        | 讓家長更清楚掌握孩子的語言實力！
      .grade-main
        .grade-ranking
          img.grade-stroke(data-scroll,:src="require('@/assets/event/trial/grade-stroke.png')")
          .grade-levels
            .grade-level(data-scroll)
              img(:src="require('@/assets/event/trial/grade-baby.png')")
              h3
                | Level
                span.grade-scondary.ml-1 0
              p 啟蒙階段
              .grade-detail
                button.btn.text-white.grade-level-btn(@click="openGradeModal('zero')",type="button") 瞭解更多
                .grade-modal(:class="{'d-none':!level.zero}")
                  h4 走出英文排斥期
                  .d-flex
                    .grade-tag.text-white 單字量
                    p.ml-2 約 500 字
                  .grade-tag.text-white 教學目標
                  ol
                    li 英語學習啟蒙
                    li 激發英語學習興趣
                    li 建立英語學習信心
                    li 對英文文字的認知
              .dec
            .grade-level(data-scroll)
              img(:src="require('@/assets/event/trial/grade-brain.png')")
              h3
                | Level
                span.grade-scondary.ml-1 1 ~ 2
              p 英語思維建立階段
              .grade-detail
                button.btn.text-white.grade-level-btn(@click="openGradeModal('one')",type="button") 瞭解更多
                .grade-modal(:class="{'d-none':!level.one}")
                  h4 學習以「英文」思考，建立語感
                  .d-flex
                    .grade-tag.text-white 單字量
                    p.ml-2 約 700~900 字
                  .grade-tag.text-white 教學目標
                  ol
                    li 初步發展英語思維能力
                    li 主題式情境學習
                    li 理解並使用簡單句子問答
                    li 解決簡單問題
              .dec
            .grade-level(data-scroll)
              img(:src="require('@/assets/event/trial/grade-speak.png')")
              h3
                | Level
                span.grade-scondary.ml-1 3 ~ 4
              p 語言形成階段
              .grade-detail
                button.btn.text-white.grade-level-btn(@click="openGradeModal('three')",type="button") 瞭解更多
                .grade-modal(:class="{'d-none':!level.three}")
                  h4 理解能力成長，表達能力完整
                  .d-flex
                    .grade-tag.text-white 單字量
                    p.ml-2 約 1400~2500 字
                  .grade-tag.text-white 教學目標
                  ol
                    li 累積進階英文詞彙
                    li 英文閱讀素想語技巧養成
                    li 能處理較複雜或抽相的資訊交流
                    li 培養解決綜合任務的能力
              .dec
        img.grade-teacher(data-scroll ,:src="require('@/assets/event/trial/grade-teacher.png')")
      //- .grade-modals
      //- .grade-modal(:class="{'d-none':!level.zero}")
      //-   h4 走出英文排斥期
      //-   .d-flex
      //-     .grade-tag.text-white 單字量
      //-     p.ml-2 約 500 字
      //-   .grade-tag.text-white 教學目標
      //-   ol
      //-     li 英語學習啟蒙
      //-     li 激發英語學習興趣
      //-     li 建立英語學習信心
      //-     li 對英文文字的認知
      //- .grade-modal(:class="{'d-none':!level.one}")
      //-   h4 學習以「英文」思考，建立語感
      //-   .d-flex
      //-     .grade-tag.text-white 單字量
      //-     p.ml-2 約 700~900 字
      //-   .grade-tag.text-white 教學目標
      //-   ol
      //-     li 初步發展英語思維能力
      //-     li 主題式情境學習
      //-     li 理解並使用簡單句子問答
      //-     li 解決簡單問題
      .grade-modal(:class="{'d-none':!level.three}")
        h4 理解能力成長，表達能力完整
        .d-flex
          .grade-tag.text-white 單字量
          p.ml-2 約 1400~2500 字
        .grade-tag.text-white 教學目標
        ol
          li 累積進階英文詞彙
          li 英文閱讀素想語技巧養成
          li 能處理較複雜或抽相的資訊交流
          li 培養解決綜合任務的能力

  .recommend
    .recommend-title(data-scroll)
      img(:src="require('@/assets/event/trial/title-leaft.png')")
      span 快樂學習 好評推薦
      img(:src="require('@/assets/event/trial/title-leaft.png')")

    p.recommend-subtitle
      |  所有 WUWOW Jr 的孩子們，
      br.mobile
      | 每天最期待的事—展開英文學習！
    .home-container(data-scroll)
      carousel.p-2(data-scroll,:perPage="1",:perPageCustom="[[768, 3],[992, 4]]" :autoplay="true" :autoplayTimeout="5000" :speed="1000" :loop="true" :autoplayHoverPause="true" :navigationEnabled="true")
        slide.justify-content-center(v-for="(card, index) in cardList")
          .slider-card
            //- 卡片縮圖
            .thumb.text-center
              span.tag {{card.tag}}
              //- 圖片
              img(:src="card.thumb" :alt="`${card.topic}的預覽圖片`")
              //- 播放按鈕
              button.play(v-if="card.video",@click="youtubeModal(card.embed)")
                //- 播放 SVG
                svg(width='40' height='40' viewbox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg')
                  circle(cx='20' cy='20' r='20' fill='#82C1EA')
                  path(d='M28.6638 19.4617C29.3305 19.8466 29.3305 20.8089 28.6638 21.1938L16.9097 27.98C16.2431 28.3649 15.4097 27.8838 15.4097 27.114L15.4097 13.5415C15.4097 12.7717 16.2431 12.2906 16.9097 12.6755L28.6638 19.4617Z' fill='white')
                //- 播放 SVG

              //- Youtube 內嵌影片
              //- .embed-responsive.embed-responsive-21by9
              //-   iframe(v-if="card.video",width='254' height='160', :src='card.thumb', title='YouTube video player',frameborder='0', allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' ,allowfullscreen='')

            //- 卡片內容
            .card-body
              .info.text-center
                p.topic {{card.topic}}
                .detail
                  P {{card.name}}
                  p(v-if="card.age") &ensp;{{card.age}}歲
              .card-title.d-flex.justify-content-center.align-items-center: h5 {{card.title}}
              p.content {{card.content}}
              .control.d-flex

                //- 圖片模式
                a.link.d-flex.ml-auto.align-items-center(:href="card.link", target='_blank')
                  span 觀看更多
                    //- 箭頭 SVG
                    svg(width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg')
                      circle(cx='10' cy='10' r='9.3' fill='white' stroke='#C7C7C7' stroke-width='1.4')
                      path(d='M4.87818 9.58436C4.98848 9.47405 5.13809 9.41208 5.29408 9.41208H12.1086L9.58301 6.88766C9.47257 6.77722 9.41052 6.62743 9.41052 6.47124C9.41052 6.31505 9.47257 6.16526 9.58301 6.05481C9.69346 5.94437 9.84325 5.88232 9.99944 5.88232C10.1556 5.88232 10.3054 5.94437 10.4159 6.05481L13.9449 9.58383C13.9997 9.63847 14.0431 9.70337 14.0728 9.77483C14.1024 9.84628 14.1177 9.92289 14.1177 10.0003C14.1177 10.0776 14.1024 10.1542 14.0728 10.2257C14.0431 10.2971 13.9997 10.362 13.9449 10.4167L10.4159 13.9457C10.3612 14.0004 10.2963 14.0438 10.2248 14.0734C10.1534 14.103 10.0768 14.1182 9.99944 14.1182C9.9221 14.1182 9.84552 14.103 9.77407 14.0734C9.70262 14.0438 9.6377 14.0004 9.58301 13.9457C9.52833 13.891 9.48495 13.8261 9.45535 13.7546C9.42576 13.6832 9.41052 13.6066 9.41052 13.5293C9.41052 13.4519 9.42576 13.3754 9.45535 13.3039C9.48495 13.2325 9.52833 13.1675 9.58301 13.1128L12.1086 10.5884H5.29408C5.13809 10.5884 4.98848 10.5265 4.87818 10.4162C4.76788 10.3059 4.70591 10.1562 4.70591 10.0003C4.70591 9.84426 4.76788 9.69466 4.87818 9.58436Z' fill='#C7C7C7')

  .form-lower
    .form-area
      img.form-title.mobile(:src="require('@/assets/event/trial/form-lower-title-mobile.png')")
      img.form-title.tablet(:src="require('@/assets/event/trial/form-lower-title-tablet.png')")
      img.form-title.desktop(:src="require('@/assets/event/trial/form-lower-title-desktop.png')")
      .form-card
        .form-input
          .icon: img(:src="require('@/assets/event/trial/member-blue.svg')")
          input(v-model="formLower.parentName",type="text",placeholder="家長姓名（必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/phone-blue.svg')")
          input(v-model="formLower.parentPhone",type="text",placeholder="家長電話（必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/mail-blue.svg')")
          input(v-model="formLower.parentEmail",type="text",placeholder="家長email（必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/kid-blue.svg')")
          input(v-model="formLower.studentChineseName",type="text",placeholder="孩子中文姓名（非必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/kid-a-blue.svg')")
          input(v-model="formLower.studentEnglishName",type="text",placeholder="小孩英文名稱（非必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/kid-five-blue.svg')")
          input(v-model="formLower.studentAge",type="text",placeholder="孩子年齡（非必填）")

        .form-privacy
          input#privacy-lower(v-model="formLower.privacyPolicyCheck",type="checkbox")
          label(for="privacy-lower")
            img(:src="require('@/assets/event/trial/check.svg')")
          span 我同意 WUWOW 線上英文&ensp;
            router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策

        .form-divider
          img(:src="require('@/assets/event/trial/divider-left.svg')")
          span 好禮領取
          img(:src="require('@/assets/event/trial/divider-right.svg')")
        .gift-area.mb-2.mb-xl-4
          button.gift.w-100.ml-0(@click="doc.content = docList[0]; doc.isOpen = true")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            |  兒童線上英文家教課1堂
        .gift-area
          button.gift(type="button" @click="galleryShow = true")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            | 劍橋兒童英檢單字書
          button.gift(@click="doc.content = docList[1]; doc.isOpen = true")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            | 孩子英文能力報告

        button.form-btn(@click="submitForm(formLower)") 免費搶先預約

  //- 移植JR首頁，footer
  .lioshutan-footer.bg-lioshutan.py-4
    //- 手機用
    .d-block.d-md-none.py-4
      .text-center
        a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
          h5.mb-4.text-light 合作提案
        a.text-decoration-none(:href="joinUsUrl" target="_blank")
          h5.mb-4.text-light 夥伴招募
        a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
          h5.mb-4.text-light 成為老師
      .text-light.text-center.contact
        h5 聯絡我們
        a.text-light(href="tel:0800885315") Tel: 0800-885315
        a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
        .d-flex.mt-4.justify-content-center
          a.mr-1.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
            img(:src="require('@/assets/event/trial/icon-fb.svg')")
            p wuwow jr
          //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
          //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
      a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.py-5
        img.w-50.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
        h6 WUWOW
        h6 最有效的線上英文
      a(href="/").d-flex.flex-column.align-items-center.text-light
        img.w-50.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
        h6 WUWOW Junior
        h6 專為孩子設計的線上英文

    //- 給平板的尺寸
    .d-none.d-md-block.d-lg-none
      .container
        .row.py-4
          .col-sm-6
            .text-center
              a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
                h5.mb-4.text-light 合作提案
              a.text-decoration-none(:href="joinUsUrl" target="_blank")
                h5.mb-4.text-light 夥伴招募
              a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
                h5.mb-4.text-light 成為老師
            .text-light.text-center.contact
              h5 聯絡我們
              a.text-light(href="tel:0800885315") Tel: 0800-885315
              a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
              .d-flex.mt-4.justify-content-center
                a.mr-1.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
                  img(:src="require('@/assets/event/trial/icon-fb.svg')")
                  p wuwow jr
              //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
              //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
          .col-sm-6
            a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.mb-3
              img.w-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
              h6 WUWOW
              h6 最有效的線上英文
            a(href="/").d-flex.flex-column.align-items-center.text-light
              img.w-75.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
              h6 WUWOW Junior
              h6 專為孩子設計的線上英文
    //- 電腦用
    .d-none.d-md-none.d-lg-block.container
      .d-flex.flex-row.justify-content-around.py-5
        div
          a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
            h5.mb-4.text-light 合作提案
          a.text-decoration-none(:href="joinUsUrl" target="_blank")
            h5.mb-4.text-light 夥伴招募
          a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
            h5.mb-4.text-light 成為老師
        .text-light.contact
          h5 聯絡我們
          a.text-light(href="tel:0800885315") Tel: 0800-885315
          a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
          .d-flex.mt-4.justify-content-left
            a.mr-1.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
              img(:src="require('@/assets/event/trial/icon-fb.svg')")
              p wuwow jr
          //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
          //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
        a.w-25(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
          h6 WUWOW
          h6 最有效的線上英文
        a.w-25(href="/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
          h6 WUWOW Junior
          h6 專為孩子設計的線上英文

  //- 滑動至表單
  button.scrollForm(@click="scrollTop()")
    img.init(:src="require('@/assets/event/trial/scrollForm.png')")
    img.hover(:src="require('@/assets/event/trial/scrollForm-hover.png')")

  transition()
    .docModal(v-if='doc.isOpen')
      span.doc-close(@click='doc.isOpen=false')
        //-  close
        svg(width='30' height='30' viewbox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z' fill='white')
        //-  close
      h3.doc-title {{doc.content.title}}
      h3.doc-subtitle {{doc.content.subTitle}}
      .doc-content
        img(:src='doc.content.imgPath' alt='')

  transition()
    .gradeModal(v-if='grade.isOpen',@click.self='grade.isOpen=false')
      span.grade-close(@click='grade.isOpen=false')
        //-  close
        svg(width='30' height='30' viewbox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z' fill='white')
      .grade-content(@click.self='grade.isOpen=false')
        .grade-dialog()
          h4 {{ grade.content.title }}
          .d-flex
            .grade-tag.text-white 單字量
            p.ml-2 約 {{ grade.content.amount }} 字
          .grade-tag.text-white 教學目標
          ol
            li(v-for="list in grade.content.list") {{ list }}

  transition()
    .reasonModal(v-if='reason.isOpen',@click.self='reason.isOpen=false')
      .reason-content(@click.self='reason.isOpen=false')
        .reason-dialog
          span.reason-close(@click='reason.isOpen=false')
            //-  close
            svg(width='30' height='30' viewbox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z' fill='white')
          .d-flex.align-items-start.d-md-block.text-md-center
            img(:src="reason.content.imgPath")
            div
              h3.learnReason-scondary {{ reason.content.title }}
              img.dec(:src="require('@/assets/event/trial/dec.png')")
              p(v-html="reason.content.pHTML")
          span.leaf.leaf-M
          span.leaf.leaf-L
          span.leaf.leaf-S

  youtube-modal(:src="youtubeUrl")
  gallery-modal(:gallery-show.sync="galleryShow")
</template>

<style lang="scss">
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}

p,
label {
  margin-bottom: initial;
}

button {
  border: none;
}

main {
  --major-color: #ffa756;
  --secondary-color: #75cdec;
  --text-major: #505050;
  --text-secondary: #919191;

  min-width: 375px;
  background-color: white;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.06em;

  /* RWD */
  /* mobile first */
  .tablet,
  .desktop {
    display: none;
  }

  /* tablet */
  @include md {
    .tablet {
      display: initial;
    }

    .mobile,
    .desktop {
      display: none;
    }
  }

  /* desktop */
  @include xl {
    .desktop {
      display: initial;
    }

    .mobile,
    .tablet {
      display: none;
    }
  }
}

/* 共用表單元素 */
.form-input {
  display: flex;

  border-radius: 5px;
  border: 1px solid var(--major-color);

  &:nth-child(n + 2) {
    margin-top: 27px;
  }

  .icon {
    border-right: 1px solid var(--major-color);

    img {
      height: 40px;
    }
  }

  input {
    flex-grow: 1;
    border: none;

    color: var(--text-secondary);
    letter-spacing: 0.06em;
    padding: 9px 10px;

    &:focus-visible {
      outline: none;
    }
  }
}

.form-privacy {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 31px;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-major);

  a {
    font-weight: 700;
    color: var(--major-color);
  }

  label {
    margin-right: 6px;
    border-radius: 2px;
    border: 1px solid var(--major-color);
    width: 13px;
    height: 13px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="checkbox"] {
    display: none;

    background-color: white;

    &:checked+label {
      background-color: var(--major-color);
    }
  }
}

.form-btn {
  margin-top: 40px;

  width: 100%;
  border-radius: 25px;
  padding: 10px;
  color: white;
  background-color: var(--major-color);

  transition: background-color 0.35s ease-in-out;

  &:hover {
    background-color: var(--text-major);
  }
}

.form-divider {
  @include xl {
    text-align: center;
  }

  width: 100%;
  overflow: hidden;
  margin: 30px auto 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    flex-shrink: 0;
    margin: 0 15px;

    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    color: var(--text-major);

    @include md {
      font-size: 16px;
      line-height: 22px;
    }
  }

  img {
    object-fit: contain;
  }
}

.highlight {
  color: var(--major-color);
}

.header {
  width: 100%;
  z-index: 2;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);

  .container {
    margin: 0 auto;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include md {
      justify-content: space-between;
    }
  }

  .contact {
    display: none;
    font-size: 16px;
    line-height: 22px;

    transition: color 0.35s ease-out;
    color: var(--text-major);
    text-decoration: none;

    &:hover {
      color: var(--major-color);
    }

    @include md {
      display: inherit;
    }
  }
}

/* 上方表單區域 */
.form-upper {
  overflow: hidden;
  padding: 0px 16px 40px;
  background-image: url('~@/assets/event/trial/form-upper-ball.png');
  background-repeat: no-repeat;
  background-position: 0% 0%;

  @include md {
    padding: 0px 40px 40px;
  }

  @include xl {
    display: flex;
    justify-content: space-between;
    padding: 0 0 130px;
    align-items: flex-start;
  }

  .form-visual {

    .visual-background {
      width: 100%;
    }

    button {
      background: none;
      img {
        transition: transform .4s ease;
      }
      &:hover img {
        transform: scale(1.06);
      }
    }

    .sm {
      @include md {
        display: none;
      }
    }

    .lg {
      display: none;

      @include md {
        display: inherit;
      }
    }

    position: relative;

    .visual-a,
    .visual-b,
    .visual-c,
    .visual-d {
      position: absolute;

      img {
        filter: drop-shadow(0px 19.2px 30px rgba(255, 143, 79, 0.18));
      }

      /* 滑動動畫 */
      transition: opacity 0.6s ease-out,
      transform 1s ease-out;
      transition-delay: 0s;
      opacity: 0;
      transform: translateX(50%);

      &.animate-active {
        opacity: 1;
        transform: translateX(0)
      }

      /* 滑動動畫 end */
    }

    .visual-a {
      top: 120px;
      left: -10px;

      @include md {
        top: 100px;
        left: 60px;
      }

      @include xl {
        top: 80px;
        left: 30px;
      }
    }

    .visual-b {
      transition-delay: 0.6s;
      bottom: 50px;
      left: 0px;

      @include md {
        bottom: 200px;
        left: 80px;
      }

      @include xl {
        bottom: 230px;
        left: 50px;
      }
    }

    .visual-c {
      transition-delay: 1.2s;
      top: 80px;
      right: 0px;

      @include md {
        top: 160px;
        right: 140px;
      }

      @include md {
        top: 150px;
        right: 80px;
      }
    }

    .visual-d {
      transition-delay: 1.8s;
      bottom: 40px;
      right: 10px;

      @include md {
        bottom: 100px;
        right: 110px;
      }

      @include xl {
        bottom: 180px;
        right: 160px;
      }
    }

    /* 星型裝飾 */
    .spark {
      position: absolute;
      animation: blink 1.5s ease-in-out infinite;
      transform: scale(0.4);

      @keyframes blink {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @include md {
        transform: scale(0.6);
      }

      @include xl {
        transform: scale(1);
      }

      &.spark-small {
        top: calc(75px + 40%);
        right: 90px;

        @include md {
          right: 180px;
        }

        @include xl {
          right: 120px;
        }
      }

      &.spark-medium {
        animation-delay: 2s;
        top: calc(75px + 3%);
        right: 60px;

        @include md {
          right: 220px;
        }

        @include xl {
          right: 170px;
        }
      }

      &.spark-large {
        animation-delay: 5s;
        top: calc(75px + 15%);
        left: 50px;

        @include md {
          left: 160px;
        }

        @include xl {
          left: 100px;
        }
      }
    }

    @include xl {
      flex-grow: 1;
      order: 1;
      object-fit: contain;
    }
  }

  .form-area {
    @include md {
      display: flex;
      flex-direction: column;
    }

    @include xl {
      margin-top: 84px;
      margin-left: 167px;
    }
  }

  .title-area {
    @include md {
      align-self: center;
    }

    @include xl {
      align-self: start;
    }
  }

  .main-title {
    display: flex;
    justify-content: center;
    align-items: end;

    font-weight: 700;
    font-size: 26px;
    line-height: 35px;

    /* 滑動動畫 */
    p {
      transition: opacity 1s ease-out, transform 1s ease-out;
      opacity: 0;
      transform: translateX(50%);

      &.animate-active {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @include md {
      font-size: 40px;
      line-height: 54px;
    }

    @include xl {
      justify-content: start;

      font-size: 40px;
      line-height: 54px;
    }

    img {
      margin-left: 34px;
      width: 43.03px;

      @include md {
        margin-left: 15px;
        height: 51.48px;
      }

      @include xl {
        margin-left: 19px;
        height: 63.36px;
      }
    }
  }

  .subtitle {
    margin-top: 7px;

    text-align: center;
    font-weight: 700;
    font-size: 16.7026px;
    line-height: 23px;

    /* 滑動動畫 */
    &>span {
      @for $i from 1 through 2 {
        &:nth-child(#{$i}) {
          transition: opacity 0.8s ease-out, transform 0.8s ease-out;
          transition-delay: $i * 0.6s;
          opacity: 0;
          transform: translateX(50%);
          display: inline-block;
        }
      }

      &.animate-active {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @include md {
      margin-top: 10px;

      font-size: 22.4px;
      line-height: 31px;
    }

    @include xl {
      margin-top: 13px;

      font-size: 28px;
      line-height: 38px;
    }

    p:last-child {
      margin-left: 4px;

      @include md {
        margin-left: 6px;
      }

      @include xl {
        margin-left: 7px;
      }
    }
  }

  .form-banner {
    // margin-top: 21px;
    text-align: center;

    /* 滑動動畫 */
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateX(50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0);
    }

    @include md {
      text-align: center;
    }

    /* width: 100%; */
    /* border-radius: 20px; */
    /* background-image: linear-gradient(180deg, #ffb865 0%, #ff8c56 100%); */

    /* padding: 33px 0 38px calc(235px + 3px); */
    /* color: white; */

    /* font-weight: 700; */
    /* font-size: 18px; */
    /* line-height: 22px; */

    /* position: relative; */

    /* img {
      width: 100%;
      position: absolute;
      bottom: -11px;
      left: -1px;
    } */
  }

  .form-card {
    margin-top: 20px;
    padding: 40px 20px;

    background-color: white;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 0px 20px 20px 20px;

    /* 滑動動畫 */
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    transition-delay: 0.3s;
    opacity: 0;
    transform: translateX(50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0);
    }

    @include md {
      margin-top: 40px;
      padding: 40px;
    }

    @include xl {
      margin-top: 42px;
    }
  }

  .gift-area {
    padding: 0 27px;
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
      justify-content: center;
    }

    button,a {
      /* border-radius: 10px; */
      /* border: 2px solid #ffdbb9; */
      // padding: 10px 20px;
      padding: 10px;

      background-color: #fff7ee;

      color: var(--text-major);
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      /* 繞圈動畫 */
      position: relative;
      overflow: hidden;

      span:nth-child(1) {
        position: absolute;
        display: block;
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, var(--major-color));
        animation: btn-top 2s linear infinite;

        @keyframes btn-top {
          0% {
            left: -100%;
          }

          50%,
          100% {
            left: 100%;
          }
        }
      }

      span:nth-child(2) {
        position: absolute;
        display: block;
        top: -100%;
        right: 0%;
        width: 2px;
        height: 100%;
        background: linear-gradient(90deg, transparent, var(--major-color));
        animation: btn-right 2s linear infinite;
        animation-delay: 0.5s;

        @keyframes btn-right {
          0% {
            top: -100%;
          }

          50%,
          100% {
            top: 100%;
          }
        }
      }

      span:nth-child(3) {
        position: absolute;
        display: block;
        bottom: 0%;
        right: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, transparent, var(--major-color));
        animation: btn-bottom 2s linear infinite;
        animation-delay: 1s;

        @keyframes btn-bottom {
          0% {
            right: -100%;
          }

          50%,
          100% {
            right: 100%;
          }
        }
      }

      span:nth-child(4) {
        position: absolute;
        display: block;
        left: 0%;
        right: -100%;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, transparent, var(--major-color));
        animation: btn-left 2s linear infinite;
        animation-delay: 1.5s;

        @keyframes btn-left {
          0% {
            bottom: -100%;
          }

          50%,
          100% {
            bottom: 100%;
          }
        }
      }

      /* 繞圈動畫 end */

      &:hover {
        transition: background-color 0.35s ease-out, color 0.35s ease-out;
        /* border-color: var(--major-color); */
        background-color: var(--major-color);
        color: white;
      }

      img {
        height: 30px;
        margin-right: 10px;
      }

      &:last-of-type {
        margin-top: 10px;

        @include md {
          margin-top: initial;
          margin-left: 10px;
        }
      }
    }
  }
}

.question {
  padding: 40px 16px;

  background-image: url("~@/assets/event/trial/bg-question-desktop.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left center;

  @include md {
    padding: 60px 40.25px 19.5px;
    /* background-image: url("~@/assets/event/trial/bg-question-tablet.png"); */
  }

  @include xl {
    padding: 100px 0px 100px;
    /* background-image: url("~@/assets/event/trial/bg-question-desktop.png"); */
  }

  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 100%;
    }

    @include xl {
      margin: 0 auto;
      max-width: 1280px;
    }
  }

  .saying-container {
    width: 100%;
    text-align: center;

    /* 滑動動畫 */
    .question-visual {
      transition: opacity 1.2s ease-out, transform 1.2s ease-out;
      opacity: 0;
      transform: translateY(-50%);

      &.animate-active {
        opacity: 1;
        transform: translateY(0)
      }
    }

    @include xl {
      display: flex;
      justify-content: center;
      align-items: center;

      .question-visual {
        order: 2;
        margin-left: 40px;
      }
    }
  }

  .saying {
    width: max-content;
    margin: 0 auto;
    padding-top: 20px;
    position: relative;

    @include md {
      padding-top: 40px;
    }

    @include xl {
      padding-top: 80px;
    }

    img.globe {
      position: absolute;
      height: 100%;
      bottom: 0%;
      left: -5%;

      @media screen and (min-width: 1280px) {
        left: -250px;
      }
    }
  }

  .famous {
    display: flex;
    align-items: center;

    &:nth-of-type(n + 2) {
      margin-top: 20px;
    }

    /* 滑動動畫 */
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        transition: opacity 1s ease-out, transform 1s ease-out;
        /* transition-delay: ($i - 1) * 0.6s; */
        transition-delay: 0.6s;
        opacity: 0;
        transform: translateY(-50%);
      }
    }

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    @include md {
      &:nth-of-type(n + 2) {
        margin-top: 66px;
      }

      &:nth-of-type(1) {
        /* transform: translateX(10%); */
        padding-left: 10%;
      }

      &:nth-of-type(2) {
        /* transform: translateX(15%); */
        padding-left: 15%;
      }

      &:nth-of-type(3) {
        /* transform: translateX(5%); */
        padding-left: 5%;
      }
    }

    .link {
      display: none;

      @include md {
        display: initial;
        margin-right: 20px;
      }

      @include xl {
        margin-right: 40px;
      }
    }

    .avatar {
      width: 60px;

      @include md {
        width: 100px;
      }

      @include xl {
        width: 140px;
      }
    }

    .bubble {
      text-align: start;
      border-radius: 20px;
      z-index: 1;
      margin-left: 20px;
      padding: 30px 10px;
      max-width: 263px;

      background-color: white;
      color: var(--text-major);
      font-size: 14px;
      line-height: 20px;

      position: relative;

      @include xl {
        font-size: 16px;
        line-height: 22px;
      }

      @include md {
        max-width: 356px;
        padding: 30px 40px;
      }

      @include xl {
        max-width: 443px;
        padding: 36px 40px;
      }

      .quotation-left {
        position: absolute;
        height: 20px;
        top: 0%;
        left: 0%;

        @include xl {
          height: 26px;
        }
      }

      .quotation-right {
        position: absolute;
        height: 20px;
        bottom: 0%;
        right: 0%;

        @include xl {
          height: 26px;
        }
      }
    }
  }

  .summary {
    margin-top: 20px;
    width: 100%;

    border-radius: 20px;
    background: rgba(255, 228, 159, 0.4);
    padding: 40px 23.5px;

    text-align: center;
    color: var(--text-major);

    position: relative;

    @include md {
      margin-top: 40px;
      padding: 40px 80px 60px;
    }

    @include xl {
      margin-top: 80px;
    }

    .quotation-left {
      position: absolute;
      height: 24px;
      top: 10px;
      left: 10px;

      @include md {
        height: 36px;
        top: 20px;
        left: 20px;
      }
    }

    .quotation-right {
      position: absolute;
      height: 24px;
      bottom: 10px;
      right: 10px;

      @include md {
        height: 36px;
        bottom: 20px;
        right: 20px;
      }
    }

    p:first-of-type {
      font-size: 14px;
      line-height: 20px;

      /* 滑動動畫 */
      transition: opacity 0.8s ease-out, transform 0.8s ease-out;
      opacity: 0;
      transform: translateY(-50%);

      &.animate-active {
        opacity: 1;
        transform: translateY(0)
      }

      @include md {
        font-size: 18px;
        line-height: 25px;
      }
    }

    p:last-of-type {
      margin-top: 10px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;

      /* 滑動動畫 */
      transition: opacity 1s ease-out, transform 1s ease-out;
      transition-delay: 0.4s;
      opacity: 0;
      transform: translateY(-50%);

      &.animate-active {
        opacity: 1;
        transform: translateY(0)
      }

      @include md {
        margin-top: 20px;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.benefit {
  background: linear-gradient(180deg, #FFFFFF 34.9%, #FFF2E3 100%);
  text-align: center;

  position: relative;

  .benefit-title {
    margin-top: 40px;

    @include xl {
      margin-top: 87px;
    }
  }

  .card-area {
    margin-top: 41px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include xl {
      flex-direction: row;
      justify-content: center;
      margin-top: 80px;
      margin-bottom: 200px;
    }
  }

  .benefit-card {

    /* 滑動動畫 */
    @for $i from 1 through 3 {
      &.card-#{$i} {
        transition: opacity 1s ease-out, transform 1s ease-out;
        transition-delay: ($i - 1) * 0.6s;
        opacity: 0;
        transform: translateY(50%);
      }
    }

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */

    @include xl {
      &:nth-last-child(2) {
        margin-bottom: 40px;
      }
    }
  }

  .benefit-abcd {
    width: 180px;
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 19px;
    left: 50%;

    @include md {
      width: 320px;
      bottom: 40px;
    }

    @include xl {
      width: 620px;
      bottom: 80px;
    }
  }

  .bud-left {
    position: absolute;
    width: 80px;
    left: 16px;
    bottom: 0;

    @include md {
      width: 120px;
      left: 40px;
      bottom: 0;
    }
  }

  .bud-right {
    position: absolute;
    width: 80px;
    right: 16px;
    bottom: 0;

    @include md {
      width: 120px;
      right: 40px;
      bottom: 0;
    }
  }

  img.benefit-curve {
    width: 100%;
  }
}

.learning {
  /* padding-bottom: 40px; */
  padding: 40px 16px 30px;
  background: linear-gradient(180deg, #FFBA7B 0%, #FFDB96 100%) no-repeat center / cover;

  @media (min-width: 1024px) {
    background-image: url('~@/assets/event/trial/stars.svg'), url('~@/assets/event/trial/stars.svg'), linear-gradient(180deg, #FFBA7B 0%, #FFDB96 100%);
    background-size: 176px 176px, 176px 176px, 100% 100%;
    background-position: 0% 100%, 100% 15%, 100% 100%;
    background-repeat: no-repeat;
  }

  &-title {
    text-align: center;
    color: #fff;
    font-weight: 700;
    @include rwd-font(18px, 24px, 30px);
    @include rwd-lh(22px, 30px, 42px);

    /* 滑動動畫 */
    transition: opacity 1s ease-out, transform 1s ease-out;
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */

    >img {
      height: 22px;
      margin: 8px 0px;

      @include md {
        height: 30px;
      }

      @media (min-width: 1024px) {
        height: 42px;
      }
    }
  }

  &-banner {
    text-align: center;

    /* 滑動動畫 */
    transition: opacity 1s ease-out, transform 1s ease-out;
    /* transition-delay: 1s; */
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */

    img {
      display: inline-block;

      /* @media (min-width: 768px) {
        width: 100%;
        max-width: 668px;
      }

      @media (min-width: 1440px) {
        max-width: 1280px;
      } */
    }
  }

  &-main {
    margin-top: 40px;
    text-align: center;

    /* 滑動動畫 */
    /* transition: opacity 1.4s ease-out, transform 1.4s ease-out;
    transition-delay: 1.8s;
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    } */

    /* 滑動動畫 end */

    img {
      display: inline-block;
    }

    .learning-main_picXl {
      width: 90%;

      @media (min-width: 1440px) {
        width: 100%;
        max-width: 1280px;
      }
    }
  }
}

.onlinePlatform {
  padding: 60px 0;
  position: relative;
  background: url('~@/assets/event/trial/onlinePlatformBg-sm.jpg') center 100% / cover;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background: url('~@/assets/event/trial/onlinePlatformBg-lg.jpg') center 100% / cover;
  }

  @media (min-width: 1024px) {
    background: url('~@/assets/event/trial/onlinePlatformBg-xl.jpg') center 100% / cover;
  }

  &-title {
    >img {
      max-width: 325px;

      @media (min-width: 768px) {
        max-width: 394px;
      }

      @media (min-width: 1024px) {
        max-width: 554px;
      }
    }
  }

  &-table {
    overflow: auto;

    /* 滑動動畫 */
    transition: opacity 2s ease-out, transform 2s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */

    &::-webkit-scrollbar-thumb {
      background: var(--major-color);
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      height: 8px;
    }
  }
}

.learnModel {
  padding-top: 117px;
  background-image: url('~@/assets/event/trial/learnModelbgdec-line.png');
  background-repeat: no-repeat;
  background-position: 0% 0%;

  @media (min-width: 1280px) {
    /* background-size: 50vw 20vw; */
  }

  .text-major {
    color: var(--major-color);
  }

  &-container {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 16px;

    @media (min-width: 768px) {
      padding: 0 40px;
    }

    @media (min-width: 1280px) {
      padding: 0;
    }
  }

  &-wrap {
    margin-bottom: 140px;
  }

  &-students {
    display: flex;
    margin: 0 -8px 20% -8px;

    @media (min-width: 1280px) {
      margin-bottom: 0;
    }

    img {
      width: 33.333%;
      padding: 0 8px;
    }

    @media (min-width: 576px) {
      img {
        width: auto;
      }
    }

    img:first-child {
      transform: translateY(-10%);
    }

    img:nth-child(2) {
      transform: translateY(10%);
    }

    img:last-child {
      transform: translateY(-10%);
    }
  }

  &-main {

    position: relative;

    .ball-small {
      position: absolute;
      bottom: 0%;
      left: -24px;

      /* 飄浮動畫 */
      animation: float 3s ease-in-out infinite;

      /* 透明動畫 */
      transition: opacity 0.6s ease-out;
      transition-delay: 2.6s;
      opacity: 0;

      &.animate-active {
        opacity: 1;
        transform: translateY(0)
      }

      /* 透明動畫 end */

      @media (min-width:768px) {
        bottom: -17px;
        left: -20px;
      }

      @media (min-width:1280px) {
        bottom: -85%;
        left: -40px;
      }
    }

    .ball-large {
      position: absolute;
      top: -23px;
      right: 0%;

      /* 飄浮動畫 */
      animation: float 3s ease-in-out infinite;
      animation-delay: 1s;

      /* 透明動畫 */
      transition: opacity 0.6s ease-out;
      transition-delay: 2.6s;
      opacity: 0;

      &.animate-active {
        opacity: 1;
        transform: translateY(0)
      }

      /* 透明動畫 end */

      @media (min-width:768px) {
        top: -70px;
        right: -35px;
      }

      @media (min-width:1280px) {
        top: -60%;
        right: 10px;
      }
    }

    /* 飄浮動畫 */
    @keyframes float {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-10%);
      }

      100% {
        transform: translateY(0);
      }
    }

    /* learnModel 內文 */
    p {
      /* 滑動動畫 */
      transition: opacity 1s ease-out, transform 1s ease-out;
      transition-delay: 2s;
      opacity: 0;
      transform: translateX(-50%);

      &.animate-active {
        opacity: 1;
        transform: translateX(0)
      }

      /* 滑動動畫 end */
    }

    @media (min-width: 1280px) {
      width: 50%;
      margin-left: 84px;
    }
  }

  &-title {
    /* 滑動動畫 */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    transition-delay: 1s;
    opacity: 0;
    transform: translateX(-50%);

    &:nth-of-type(2) {
      transition-delay: 1.4s;
    }

    &.animate-active {
      opacity: 1;
      transform: translateX(0)
    }

    /* 滑動動畫 end */

    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    @include rwd-font(18px, 24px, 30px);
    @include rwd-lh(22px, 30px, 42px);

    @media (min-width: 1280px) {
      text-align: start;
    }

    &:nth-child(1) {
      @media (min-width: 1280px) {
        margin-bottom: 0px;
      }
    }
  }

  &-students {
    /* 滑動動畫 */
    transition: opacity 1.4s ease-out, transform 1.4s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */

    img {
      border-radius: 20px;
    }
  }
}

.learnReason {
  .learnReason-scondary {
    color: var(--secondary-color);
  }

  ul {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  padding: 40px 0 40px 0;
  background: linear-gradient(180deg, rgba(255, 229, 189, 0) 0%, rgba(255, 229, 189, 0.4) 11.46%, rgba(198, 245, 255, 0.4) 35.94%, rgba(255, 255, 255, 1) 100%) no-repeat center / cover;
  ;

  @include md {
    background-image:
      url('~@/assets/event/trial/dec-letter_left.png'),
      url('~@/assets/event/trial/dec-letter-right.png'),
      linear-gradient(180deg, rgba(255, 229, 189, 0) 0%, rgba(255, 229, 189, 0.4) 11.46%, rgba(198, 245, 255, 0.4) 35.94%, rgba(255, 255, 255, 1) 100%);
    background-size: 288px 390px, 297px 371px, cover;
    background-position: 0% 468px, 100% 478px, 100% 100%;
  }

  @media (min-width: 1280px) {
    padding: 80px 0;
  }

  &-container {
    margin: 0 auto;
    padding: 0 16px;

    @include md {
      max-width: 618px;
      padding: 0 20px;
    }

    @media (min-width: 1280px) {
      max-width: 1240px;
    }
  }

  &-title {
    h2 {
      font-weight: 700;
      @include rwd-font(18px, 24px, 30px);
      @include rwd-lh(22px, 30px, 42px);
      display: inline-block;
      position: relative;

      &::after,
      &::before {
        content: '';
        position: absolute;
        background: url('~@/assets/event/trial/title-leaft.png') no-repeat center / 50% 50%;
        width: 48px;
        height: 56px;
        transform: translateY(-8px);
      }

      &::before {
        top: 0px;
        left: -42px;
      }

      &::after {
        top: 0px;
        right: -42px;
      }
    }
  }

  .learnReason-main {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    list-style: none;

    >li {
      width: 100%;
      padding: 0 10px;

      /* 滑動動畫 */
      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          transition: opacity 0.8s ease-out, transform 0.8s ease-out;
          transition-delay: ($i - 1) * 0.2s;
          opacity: 0;
          transform: translateY(50%);
          display: inline-block;
        }
      }

      &.animate-active {
        opacity: 1;
        transform: translateX(0);
      }

      /* 滑動動畫 */

      @include md {
        width: 50%;
      }

      @media (min-width: 1280px) {
        width: 25%;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @include md {
          margin-bottom: 0;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px;

        @media (min-width: 1280px) {
          margin-bottom: 0;
        }
      }
    }
  }

  &-card {
    padding: 20px 20px 40px 20px;
    border-bottom: 10px solid #75CDEC;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 30px;
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 85.42%, #E7F9FF 100%);
    min-height: 185px;

    @include md {
      height: 375px;
    }

    .leaf {
      position: absolute;
      bottom: 0;
      background: url('~@/assets/event/trial/title-leaft.png') no-repeat center / 50% 50%;

      &-M {
        width: 50px;
        height: 50px;
        bottom: -14px;
      }

      &-S {
        width: 40px;
        height: 40px;
        right: 18%;
        bottom: -10px;
      }

      &-L {
        width: 70px;
        height: 70px;
        right: 22%;
        bottom: -20px;
      }
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0;
      @include rwd-font(16px, 20px, 20px);
      @include rwd-lh(22px, 24px, 24px);
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .dec {
      margin: 20px 0;
      width: 100%;
    }

    img {
      width: 70px;

      @media (min-width: 768px) {
        width: 130px;
      }
    }
  }

  &-order {
    background: var(--major-color);
    color: #fff;
    border-radius: 40px;
    padding: 10px 0;
    width: 300px;
    margin-bottom: 60px;
    font-size: 16px;
    line-height: 22px;

    animation: shadow-pulse 2s infinite;

    @keyframes shadow-pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 167, 86, 0.8);
      }

      100% {
        box-shadow: 0 0 0 6px rgba(255, 167, 86, 0);
      }

    }

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }

    @media (min-width: 1280px) {
      margin-bottom: 100px;
    }

    &:hover {
      color: #fff;
      background-color: lighten(#FFA756, 10%);
    }
  }

  &-bannerContainer {
    margin: 100px auto 0 auto;
    padding: 0 16px;

    @include md {
      padding: 0 40px;
    }

    @media (min-width: 1280px) {
      padding: 0;
      max-width: 1280px;
    }
  }

  &-banner {
    padding-top: 50px;
    background-image:
      url('~@/assets/event/trial/learnReason-banner2.png'),
      linear-gradient(180deg, #D2FCFF 0%, #D7F0FF 100%);
    background-repeat: no-repeat;
    background-size: 180px 180px, 100% 100%;
    background-position: 50% 35px, 100% 100%;
    box-shadow: 0px 0px 20px rgba(169, 224, 236, 0.3);
    border-radius: 30px;

    @include md {
      background-image:
        url('~@/assets/event/trial/learnReason-banner1.png'),
        url('~@/assets/event/trial/learnReason-banner2.png'),
        linear-gradient(180deg, #D2FCFF 0%, #D7F0FF 100%);
      background-size: 149.5px 136.5px, 207px 209px, 100% 100%;
      background-position: 0 81px, 105% -0%, 100% 100%;
    }

    @media (min-width: 1280px) {
      background-size: 299px 273px, 298px 300px, 100% 100%;
      background-position: 0 30px, 105% -0%, 100% 100%;
      max-width: 1280px;
    }

    h2 {
      text-align: center;
      font-weight: bold;
      @include rwd-font(18px, 20px, 30px);
      @include rwd-lh(22px, 30px, 42px);
    }
  }

  &-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    margin: 0 auto 20px auto;
    max-width: 80%;

    /* 彈跳動畫 */
    animation: bounce 1s ease-out infinite;

    @keyframes bounce {

      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }

      40% {
        transform: translateY(-20px);
      }

      60% {
        transform: translateY(-10px);
      }
    }

    /* 彈跳動畫 end */

    @include md {
      padding: 0;
      max-width: 280px;
    }

    @media (min-width: 1280px) {
      margin: 0 auto 26px auto;
      max-width: 480px;
    }

    img {
      width: 40px;

      @media (min-width: 1280px) {
        width: 60px;
      }
    }
  }

  &-more {
    color: #fff;
    border-radius: 40px;
    background-color: var(--secondary-color);
    width: 270px;
    padding: 13px 0;

    &:hover {
      background-color: lighten(#75CDEC, 10%);
      color: #fff;
    }
  }

  .btn-check:checked~.learnReason-table {
    height: auto;

    @include md {
      padding-bottom: 40px;
    }

    @media (min-width: 1280px) {
      padding-bottom: 56px;
    }
  }

  &-table {
    margin: 20px auto 0 auto;
    padding: 0 16px 0 16px;
    transition: height .4s ease;
    overflow: hidden;
    height: 0px;

    @include md {
      margin: 88px auto 0 auto;
      padding: 0 59px;
      text-align: center;
    }

    @media (min-width: 1280px) {
      margin: 60px auto 0 auto;
      padding: 0;
      max-width: 910px;
    }
  }

  &-tableWrap {
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background: var(--secondary-color);
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      height: 8px;
    }
  }
}

.grade {
  background-image: url('~@/assets/event/trial/grade-halo.png'), linear-gradient(177.46deg, rgba(255, 255, 255, 1) 2.13%, rgba(255, 255, 255, 1) 106.93%);
  background-position: 0 -20px, 100% 100%;
  background-size: 364px 364px, 100% 100%;
  background-repeat: no-repeat;

  @include md {
    background-size: 435px 435px, 100% 100%;
  }

  &-scondary {
    color: var(--secondary-color);
  }

  &-title {
    text-align: center;
    font-weight: 700;
    @include rwd-font(18px, 24px, 30px);
    @include rwd-lh(22px, 30px, 42px);
  }

  &-container {
    margin: 0 auto;
    padding: 0 16px;

    @include md {
      padding: 0;
      /* max-width: 500px; */
      max-width: 600px;
    }

    @media (min-width: 1200px) {
      max-width: 1200px;
    }
  }

  &-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    /* padding-bottom: 30%; */

    @media (min-width: 1280px) {
      padding-bottom: 30px;
    }
  }

  &-teacher {
    width: 100%;
    order: 1;
    /* z-index: 2; */
    /* position: absolute; */
    /* bottom: 8%; */
    /* pointer-events: none; */

    /* 滑動動畫 */
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateX(-50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0)
    }

    /* 滑動動畫 end */

    @include md {
      bottom: 40px;
    }

    @media (min-width: 768px) {
      position: static;
      /* max-width: 606px; */
      max-width: 560px;
      /* transform: translateY(0%); */
      /* order: 1; */
    }
  }

  &-ranking {
    order: 1;
    width: 100%;

    @media (min-width: 1200px) {
      /* width: 500px; */
      width: 600px;
      order: 2;
    }
  }

  &-levels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &-stroke {
    width: 100%;
    margin-bottom: 20px;
    /* height: 300px; */
    /* background: url('~@/assets/event/trial/grade-stroke.png') no-repeat center / 100% 191px; */

    /* 滑動動畫 */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    transition-delay: 1s;
    opacity: 0;
    transform: translatey(50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0)
    }

    /* 滑動動畫 end */
  }

  &-tag {
    display: inline-block;
    border-radius: 20px;
    padding: 4px 10px;
    margin-bottom: 20px;
    background: var(--secondary-color);
  }

  &-modals {
    position: relative;

    .grade-modal:nth-child(1) {
      top: -44vh;
      left: 0px;

      @media (min-width: 768px) {
        top: -50vh;
        left: 0px;
      }

      @media (min-width: 1280px) {
        top: -30vh;
        left: 700px;
      }
    }

    .grade-modal:nth-child(2) {
      top: -50vh;
      transform: translateX(15%);

      @media (min-width: 768px) {
        top: -56vh;
      }

      @media (min-width: 1280px) {
        top: -35vh;
        left: 700px;
      }
    }

    .grade-modal:nth-child(3) {
      top: -56vh;
      right: 0;

      @media (min-width: 768px) {
        top: -62vh;
      }

      @media (min-width: 1280px) {
        top: -40vh;
      }
    }
  }

  &-detail {
    position: relative;
  }

  &-modal {
    display: inline-block;
    text-align: left;
    width: 270px;
    top: 100%;
    left: -50%;
    position: absolute;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 20px;
    background: #fff;
    padding: 20px 14px;
    font-size: 14px;
    line-height: 20px;
    z-index: 20;

    >h4 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-level {
    display: inline-block;
    text-align: center;
    width: 30%;
    position: relative;
    z-index: 1;

    /* 滑動動畫  */
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transition: opacity 1s ease-out, transform 1s ease-out;

        transition-delay: $i * 0.2s;

        opacity: 0;
        transform: translateY(0px);
      }
    }

    &.animate-active {
      opacity: 1;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transform: translateY($i * -50px);

        }
      }

      /* transform: translateX(0); */
    }

    /* 滑動動畫 end */

    @include md {
      width: 140px;
    }

    >img {
      margin-bottom: 10px;
    }

    >h3 {
      font-weight: 700;
      @include rwd-font(18px, 20px, 24px);
      @include rwd-lh(22px, 24px, 30px);
      margin-bottom: 10px;
    }

    >p {
      @include rwd-font(14px, 16px, 16px);
      @include rwd-lh(20px, 22px, 22px);
      color: #919191;
      margin-bottom: 10px;
    }

    &-btn {
      margin-bottom: 20px;
      background: var(--secondary-color);
      border-radius: 20px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 0;

      @include md {
        width: 140px;
      }

      &:hover {
        background: #505050;
      }
    }

    >.dec {
      background: linear-gradient(180deg, #EEEEEE 0%, rgba(218, 218, 218, 0) 90.62%) no-repeat center / cover;
      height: 208px;
    }

    /* &:nth-child(1) {
      transform: translateY(-50px);
    } */

    /* &:nth-child(2) {
      transform: translateY(-100px);
    } */

    &:nth-child(3) {
      /* transform: translateY(-150px); */

      .grade-modal {
        transform: translate(-40px, 20px);
      }
    }

    /* &-modal {
      display: inline-block;
      text-align: left;
      width: 300px;
      position: absolute;
      box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
      border-radius: 20px;
      background: #fff;
      padding: 20px 14px;
      font-size: 14px;
      line-height: 20px;
      z-index: 20;

      >h4 {
        text-align: center;
        font-weight: 700;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px;
      }
    } */
  }
}

.recommend {
  padding: 40px 16px;
  background-image: url('~@/assets/event/trial/bg-recommend.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  text-align: center;

  @include md {
    padding: 60px 16px;
  }

  @include xl {
    padding: 60px 16px;
  }

  .recommend-title {

    color: var(--secondary-color);
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    /* 滑動動畫 */
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */

    @include md {
      font-size: 24px;
      line-height: 30px;
    }

    @include xl {
      font-size: 30px;
      line-height: 42px;
    }

    .home-container {
      margin-top: 40px;

      @include xl {
        margin-top: 60px;
      }
    }

    span {
      margin: 4px 20px;
    }

    img {
      height: 30px;
    }
  }

  .recommend-subtitle {
    margin-top: 12px;
    margin-bottom: 40px;

    color: var(--text-major);
    font-size: 14px;
    line-height: 20px;

    @include xl {
      margin-bottom: 60px;
    }
  }

  /* 下房圓點圖示 */
  .VueCarousel-dot--active {
    background-color: var(--main-color) !important;
  }

  /* 輪播圖設定 */
  .VueCarousel {
    margin: 0 auto;
    width: 75%;

    /* .VueCarousel-navigation-button,
    .VueCarousel-navigation-next {
      display: none;
    } */

    /* 滑動動畫 */
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(50%);

    &.animate-active {
      opacity: 1;
      transform: translateY(0)
    }

    /* 滑動動畫 end */
  }

  .VueCarousel-wrapper {
    align-self: center;
    // mobile first 手機板寬度
    width: 100%;
  }

  // 卡片 layout
  .slider-card {
    // mobile first 手機板寬度
    // width: 367px;
    margin-right: 16px;

    width: 100%;

    border-radius: 7px;
    padding: 0px 0px 24px 0px;

    background: #ffffff;
    box-shadow: 0px 6px 8px 0px #e4e4e440;

    letter-spacing: 0.1em;

    --text-major: #707070;

    .card-body {
      padding: 20px 16px 0;
    }
  }

  // 卡片縮圖
  .thumb {
    border-radius: 10px;
    overflow: hidden;

    // width: 254px;
    height: 160px;

    position: relative;

    // 標籤
    span {
      z-index: 1;
      position: absolute;
      top: 10px;
      left: 10px;

      border-radius: 5px;
      background-color: var(--main-color);
      padding: 2px 6px;

      font-size: 12px;
      line-height: 19px;
      color: white;
    }

    // 播放按鈕
    button.play {
      border: none;
      background: none;

      z-index: 1;
      position: absolute;

      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      svg circle {
        transition: fill 0.65s ease-out;
      }

      &:hover {
        svg circle {
          fill: #f4fbff;
        }

        svg path {
          fill: var(--main-color);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img,
    iframe {
      transition: transform 0.65s cubic-bezier(0.8, -0.6, 0.1, 1.4);

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  // 卡片內容
  .card-body {
    .card-title {
      height: 44px;
      margin: 10px 0 20px;

      &>* {
        margin-bottom: unset;
        font-weight: 600;

        font-size: 14px;
        line-height: 22px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .content {
      --content-line-height: 22px;
      margin-bottom: 15px;
      height: calc(var(--content-line-height) * 4);

      font-size: 14px;
      line-height: var(--content-line-height);

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .info {
    padding: 8px 21.5px;
    border-radius: 5px;
    background-color: #F4FBFF;

    --info-line-height: 17px;

    p {
      font-size: 14px;
      line-height: var(--info-line-height);

      margin-bottom: unset;
    }

    .topic {
      margin-bottom: 4px;
      height: calc(var(--info-line-height) * 2);

      color: var(--main-color);
      font-weight: 700;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .detail {
      color: var(--text-major);
      height: calc(var(--info-line-height) * 2);

      // 姓名
      p:first-child {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  // 觀看更多按鈕
  .link {
    border: unset;
    padding: 0;
    background: none;

    font-size: 14px;
    color: #c7c7c7;
    letter-spacing: 0.1em;

    span {
      transition: color 0.35s ease-out;
    }

    svg {
      margin-left: 6px;

      circle,
      path {
        transition: fill 0.35s ease-out, stroke 0.35s ease-out;
      }
    }

    &:hover {
      text-decoration: none;

      // 文字
      span {
        color: var(--main-color);
      }

      // 箭頭
      svg {
        circle {
          stroke: var(--main-color);
          fill: var(--main-color);
        }

        path {
          fill: white;
        }
      }
    }
  }

  /* 輪播圖 RWD, mobile first */
  // tablet (bootstrap md)
  @media screen and(min-width: 768px) {
    .area-description {
      font-size: 20px;
      line-height: 31px;
    }

    .VueCarousel {
      width: calc(90% + 16px);
    }

    .VueCarousel-wrapper {
      width: 684px;
    }

    .slider-card {
      width: 214px;
    }
  }

  // desktop (bootstrap lg)
  @media screen and(min-width: 992px) {
    .area-description {
      font-size: 22px;
      line-height: 34px;
    }

    .VueCarousel {
      width: calc(90% + 32px);
    }

    .VueCarousel-wrapper {
      width: 100%;
    }

    .slider-card {
      width: 204.5px;
    }
  }

  @media screen and(min-width: 1200px) {
    .VueCarousel {
      width: calc(90% + 16px);

      // 顯示輪撥按鈕
      .VueCarousel-navigation-button,
      .VueCarousel-navigation-next {
        display: block;
      }
    }

    .slider-card {
      width: 254px;
    }
  }

  @media screen and(min-width: 1600px) {
    .slider-card {
      width: 334px;
    }
  }
}

.docModal {
  position: fixed;
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 44px;

  background-color: #4f4f4fe6;
  /* background-image: radial-gradient(#ffffff00  35%, #4c4c4c 70%); */
  /* background-image: radial-gradient(circle, #ffffff00 50%, #4c4c4c 70%); */
  /* backdrop-filter: blur(10px); */
  overflow-y: scroll;

  /* vue transition */
  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
    transform: scale(1.2);
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: scale(1);
  }

  /* scroll bar */
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--secondary-color);
  }

  .doc-close {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  .doc-close:hover path {
    fill: var(--secondary-color);
  }

  .doc-close:hover,
  .doc-close:focus {
    color: var(--secondary-color);
    text-decoration: none;
    cursor: pointer;
  }

  .doc-title {
    text-align: center;
    margin: 30px auto 0;

    font-weight: 900;
    color: var(--secondary-color);
    font-size: 32px;
    line-height: 34px;
  }

  .doc-subtitle {
    text-align: center;
    margin: 50px auto 0;

    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;

    /* white */

    color: #ffffff;
  }

  .doc-content {
    margin-top: 50px;
    text-align: center;
  }

  .doc-content img {
    width: 100%;
    max-width: 700px;
  }

  .docModal::-webkit-scrollbar,
  .gallery ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .docModal::-webkit-scrollbar-track,
  .gallery ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .docModal::-webkit-scrollbar-thumb,
  .gallery ::-webkit-scrollbar-thumb {
    background-color: #c2a335;
  }
}

.gradeModal {
  position: fixed;
  min-width: 375px;
  overflow: scroll;
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 44px;

  background-color: #4f4f4fe6;
  /* background-image: radial-gradient(#ffffff00  35%, #4c4c4c 70%); */
  /* background-image: radial-gradient(circle, #ffffff00 50%, #4c4c4c 70%); */
  /* backdrop-filter: blur(10px); */
  overflow-y: scroll;

  /* vue transition */
  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
    transform: scale(1.2);
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: scale(1);
  }

  /* scroll bar */
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--secondary-color);
  }

  .grade-close {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  .grade-close:hover path {
    fill: var(--secondary-color);
  }

  .grade-close:hover,
  .grade-close:focus {
    color: var(--secondary-color);
    text-decoration: none;
    cursor: pointer;
  }

  .grade-content {
    margin-top: 50px;
    text-align: center;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grade-content img {
    width: 100%;
    max-width: 700px;
  }

  .grade-dialog {
    display: inline-block;
    width: 300px;
    text-align: left;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 20px;
    background: #fff;
    padding: 20px 14px;
    font-size: 14px;
    line-height: 20px;

    >h4 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;
    }
  }

}

.reasonModal {
  position: fixed;
  min-width: 375px;
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 44px;

  background-color: #4f4f4fe6;
  /* background-image: radial-gradient(#ffffff00  35%, #4c4c4c 70%); */
  /* background-image: radial-gradient(circle, #ffffff00 50%, #4c4c4c 70%); */
  /* backdrop-filter: blur(10px); */
  overflow-y: scroll;

  /* vue transition */
  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.35s ease-out, transform 0.35s;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
    transform: scale(1.2);
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: scale(1);
  }

  /* scroll bar */
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--major-color);
  }

  .reason-close {
    position: absolute;
    top: -40px;
    right: -40px;
  }

  .reason-close:hover path {
    fill: var(--major-color);
  }

  .reason-close:hover,
  .reason-close:focus {
    color: var(--major-color);
    text-decoration: none;
    cursor: pointer;
  }

  .reason-content {
    margin-top: 50px;
    text-align: center;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reason-content img {
    width: 100%;
    max-width: 700px;
  }

  .reason-dialog {
    padding: 20px 20px 40px 20px;
    border-bottom: 10px solid #75CDEC;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 30px;
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 85.42%, #E7F9FF 100%);
    min-height: 185px;

    @include md {
      height: 375px;
    }

    .leaf {
      position: absolute;
      bottom: 0;
      background: url('~@/assets/event/trial/title-leaft.png') no-repeat center / 50% 50%;

      &-M {
        width: 50px;
        height: 50px;
        bottom: -14px;
        left: 20px;
      }

      &-S {
        width: 40px;
        height: 40px;
        right: 18%;
        bottom: -10px;
      }

      &-L {
        width: 70px;
        height: 70px;
        right: 22%;
        bottom: -20px;
      }
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0;
      @include rwd-font(16px, 20px, 20px);
      @include rwd-lh(22px, 24px, 24px);
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .dec {
      margin: 20px 0;
      width: 100%;
    }

    img {
      width: 70px;

      @media (min-width: 768px) {
        width: 130px;
      }
    }
  }

}

.form-lower {
  padding: 40px 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("~@/assets/event/trial/bg-form-lower-mobile.png");

  @include md {
    padding: 92px 50px 80px;

    background-image: url("~@/assets/event/trial/bg-form-lower-tablet.png");
  }

  @include xl {
    display: flex;
    padding: 90px 216px 68px;
    background-image: url("~@/assets/event/trial/bg-form-lower-desktop.png");
  }

  .form-area {
    text-align: center;
  }

  .form-card {
    margin-top: 20px;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 0px 20px 20px 20px;

    background-color: white;
    padding: 40px 20px;

    @include md {
      margin-top: 40px;
      padding: 60px 40px;
    }

    @include xl {
      margin-top: 35px;
      max-width: 560px;
    }
  }

  .form-input {
    border-color: var(--secondary-color);

    .icon {
      border-color: var(--secondary-color);
    }
  }

  .form-privacy {
    a {
      color: var(--secondary-color);
    }

    label {
      border-color: var(--secondary-color);
    }

    input[type="checkbox"] {
      &:checked+label {
        background-color: var(--secondary-color);
      }
    }

    .form-divider {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .form-btn {
    margin-top: 30px;
    background-color: var(--secondary-color);

    &:hover {
      background-color: var(--text-major);
    }
  }

  .gift-area {
    /* padding: 0 27px; */
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
      justify-content: center;
    }

    button,a {
      /* border-radius: 10px;
      border: 2px solid #9DE6FF; */
      // padding: 10px 20px;
      padding: 10px;

      background-color: #eafaff;

      color: var(--text-major);
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      /* 繞圈動畫 */
      position: relative;
      overflow: hidden;

      span:nth-child(1) {
        position: absolute;
        display: block;
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, var(--secondary-color));
        animation: btn-top 2s linear infinite;

        @keyframes btn-top {
          0% {
            left: -100%;
          }

          50%,
          100% {
            left: 100%;
          }
        }
      }

      span:nth-child(2) {
        position: absolute;
        display: block;
        top: -100%;
        right: 0%;
        width: 2px;
        height: 100%;
        background: linear-gradient(90deg, transparent, var(--secondary-color));
        animation: btn-right 2s linear infinite;
        animation-delay: 0.5s;

        @keyframes btn-right {
          0% {
            top: -100%;
          }

          50%,
          100% {
            top: 100%;
          }
        }
      }

      span:nth-child(3) {
        position: absolute;
        display: block;
        bottom: 0%;
        right: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, transparent, var(--secondary-color));
        animation: btn-bottom 2s linear infinite;
        animation-delay: 1s;

        @keyframes btn-bottom {
          0% {
            right: -100%;
          }

          50%,
          100% {
            right: 100%;
          }
        }
      }

      span:nth-child(4) {
        position: absolute;
        display: block;
        left: 0%;
        right: -100%;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, transparent, var(--secondary-color));
        animation: btn-left 2s linear infinite;
        animation-delay: 1.5s;

        @keyframes btn-left {
          0% {
            bottom: -100%;
          }

          50%,
          100% {
            bottom: 100%;
          }
        }
      }

      /* 繞圈動畫 end */

      &:hover {
        transition: background-color 0.35s ease-out, color 0.35s ease-out;
        /* border-color: var(--major-color); */
        background-color: var(--secondary-color);
        color: white;
      }

      img {
        height: 30px;
        margin-right: 10px;
      }

      &:last-of-type {
        margin-top: 10px;

        @include md {
          margin-top: initial;
          margin-left: 10px;
        }
      }
    }
  }
}

.lioshutan-footer {
  *:has(.hover, .init) {
    >.hover {
      display: none;
    }

    &:hover {
      >.init {
        display: none;
      }

      >.hover {
        display: inherit;
      }
    }
  }

  .contact a {
    display: block;
    font-size: 1.09375rem;

    /* 社群連結按鈕 */
    &:has(img) {
      opacity: 0.8;

      img {
        width: 30px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .jr-logo {
      height: 100px;
    }
  }
}

button.scrollForm {
  z-index: 1;
  position: fixed;
  right: 16px;
  bottom: 16px;

  background: none;
  padding: 0;

  .hover {
    display: none;
  }

  img {
    width: 100%;
  }

  &:hover {
    .hover {
      display: inline;
    }

    .init {
      display: none;
    }
  }

  width: 89px;

  @include md {
    right: 40px;
    bottom: 40px;
  }

  @include xl {
    width: 107px;
  }

}

.mobile-banner {
  max-width: 688px;
}

.tablet-banner {
  max-width: 963px;
}

.desktop-banner {
  max-width: 500px;
}
</style>

<script>
import lioshutanApi from '@/api';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import { getEventMediaSource } from '@/utils/mediaSourse';
import httpCodeConstants from '@/constants/httpCode';
// for youtube modal
import { MODAL } from '@/store';
import { PAYLOAD } from '@/store/modules/modal';
import { IS_OPEN, SET_IS_OPEN, SET_PAYLOAD } from '@/store/modules/modal';
import { mapMutations, mapState } from 'vuex';

import youtubeModal, { uuid as youtubeModalUUID } from '@/views/home/youtubeModal/index.vue';
import galleryModal from '../components/GalleryModal.vue';

// 輪播
import { Carousel, Slide } from 'vue-carousel';

// JR 首頁 footer版面用資料
import companyConstants from '@/constants/company';

// 引入moment
import moment from 'moment';
import mobileBanner from '@/assets/event/trial/form-banner-mobile.png';
import tabletBanner from '@/assets/event/trial/form-banner-tablet.png';
import desktopBanner from '@/assets/event/trial/form-banner-desktop.png';
import alternateBanner from '@/assets/event/trial/2023_trial_jr_banner.png';

export default {
  name: 'EventTrial',

  components: { Carousel, Slide, youtubeModal, galleryModal },

  data: () => ({
    galleryShow: false,
    formUpper: {
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      privacyPolicyCheck: false,
    },
    formLower: {
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      studentChineseName: '',
      studentEnglishName: '',
      studentAge: '',
      privacyPolicyCheck: false,
    },
    level: {
      zero: false,
      one: false,
      three: false,
    },
    // for youtube modal
    youtubeModalUUID,
    // 輪播圖，好評推薦名單
    cardList: [
      {
        tag: '家長分享', // 必填
        video: false,
        // 放圖片靜態資源
        thumb: require('@/assets/recommend/thumb/thumb-janie.png'),
        topic: '飛行海蒂 小饅頭', // 必填，藍色粗體那個標題
        name: 'Janie Tsai', // 必填
        age: 5, // 選填
        title: '孩子喜歡開口最重要', // 黑色粗體那個標題
        // 必填
        content:
          '每次上完課, 小饅頭都很期待下次哪時候要再上課, 還會問我：「媽媽, 今天要上課嗎?」沒有的話還會小小失落, 真的很喜歡上課耶, 完全與他們「最樂學的兒童線上英文」的品牌精神相符合！小孩喜歡、有動力繼續上真的是學習的關鍵, 爸媽的錢才會花 在刀口上。',
        link: 'https://heidi.com.tw/wuwow-junior/',
      },
      {
        tag: '體驗邀請', // 必填
        video: false,
        // 放圖片靜態資源
        thumb: require('@/assets/recommend/thumb/thumb-dean.png'),
        topic: '部落格個人品牌教練&顧問', // 必填，藍色粗體那個標題
        name: 'Dean', // 必填
        age: false, // 選填
        title: '課程、平台、師資各方面完善，值得推薦', // 黑色粗體那個標題
        // 必填
        content:
          '在評測體驗過多家兒童家教品牌後，WUWOW Jr 的優點包含：多元預約方式、老師互動能力佳、專為兒童打造的教材、客服專業、平台使用體驗佳...等等，在各方面算是非常完善，值得推薦給大家。',
        link: 'https://deanlife.blog/wuwow-junior-review/',
      },
      {
        tag: '家長分享', // 必填
        video: false,
        // 放圖片靜態資源
        thumb: require('@/assets/recommend/thumb/thumb-alex.png'),
        topic: '圓圓家的移動城堡 圓圓', // 必填，藍色粗體那個標題
        name: 'Alex Chen', // 必填
        age: 9, // 選填
        title: '量身定做最適合的線上英語教學，輕鬆活潑，學習超有趣', // 黑色粗體那個標題
        // 必填
        content:
          '除了外師也有雙語中師的線上教學，課程設計生動活潑引導孩子自然而然喜歡上英文，讓孩子不再恐懼開口說，教學內容按照孩子各階段英文能力量身訂做出專屬課程，搭配上百種生動活潑的教材，讓孩子愛上英文課! 整體上課感受非常符合他們「最樂學的兒童線上英文」的品牌精神。',
        link: 'https://vivi0010.pixnet.net/blog/post/232326823',
      },
      {
        tag: '學員心得', // 必填
        video: true,
        // 放圖片靜態資源
        thumb: require('@/assets/recommend/thumb/thumb-abby.png'), // 必填
        // 放 youtube embed 連結
        embed: 'https://www.youtube.com/embed/8sNQG-lbVrE',
        topic: '學員分享', // 必填，藍色粗體那個標題
        name: 'Abby Wu', // 必填
        age: 10, // 選填
        title: '從不敢開口到成為日常，樂學英文帶來巨大改變', // 黑色粗體那個標題
        // 必填
        content:
          '自從在 WUWOW Jr 學習，我覺得上英文是一件快樂的事，沒有壓力，自然而然比較敢開口，錯了也不會覺得害羞！',
        link: 'https://www.youtube.com/watch?v=8sNQG-lbVrE',
      },
    ],
    // doc modal
    doc: {
      isOpen: false,
      content: { title: '', subTitle: '', imgPath: '' },
    },
    // 再送內容
    docList: [
      { title: '真⼈1對1課程', subTitle: '體驗WUWOW為您打造的英文環境', imgPath: require('@/assets/event/cross-industry/1_on_1_class.png') },
      { title: '英⽂能⼒分析', subTitle: '了解你現在的英文程度', imgPath: require('@/assets/event/trial/modal-report.png') }],
    grade: {
      isOpen: false,
      content: { title: '', amount: '', list: [] },
    },
    gradeList: [
      {
        title: '走出英文排斥期', amount: '500', list: ['英語學習啟蒙', '激發英語學習興趣', '建立英語學習信心', '對英文文字的認知'],
      },
      {
        title: '學習以「英文」思考，建立語感', amount: '700~900', list: ['初步發展英語思維能力', '主題式情境學習', '理解並使用簡單句子問答', '解決簡單問題'],
      },
      {
        title: '理解能力成長，表達能力完整', amount: '1400~2500', list: ['累積進階英文詞彙', '英文閱讀素想語技巧養成', '能處理較複雜或抽相的資訊交流', '培養解決綜合任務的能力'],
      },
    ],
    reason: {
      isOpen: false,
      content: { title: '', imgPath: '', pHTML: '' },
    },
    reasonList: [
      {
        title: '25 分鐘 1 對 1 家教',
        imgPath: require('@/assets/event/trial/learnReason1.png'),
        pHTML: '短時間專注學習，<br>家教型VIP精緻學<br>寶貝進步超有感！',
      },
      {
        title: '課綱明確 分級嚴謹',
        imgPath: require('@/assets/event/trial/learnReason2.png'),
        pHTML: '以明確課綱對應國際標準分級，<br>漸進提升孩子的英文，<br>與國際接軌。',
      },
      {
        title: '主題沈浸式 互動教學',
        imgPath: require('@/assets/event/trial/learnReason3.png'),
        pHTML: '以日常情境激發學習熱情，<br>在互動中同步練習<br>聽、說、讀、寫，<br>全方位沈浸式英文學習。',
      },
      {
        title: '嚴選師資 汰弱留強',
        imgPath: require('@/assets/event/trial/learnReason4.png'),
        pHTML: '師資皆有英文教學認證<br>及兒美教學經驗，<br>輔以定期回訓汰留，<br>維持最佳教學品質。',
      },
    ],
    pageMediaSource: '',
    privacyUrl: '/privacy',
    thankYouUrl: '/thank-you',
    // 用來存放 ref 值的流水號
    refCount: {},
    // JR 首頁 預約試聽版面用資料

    // JR 首頁 footer版面用資料
    cooperationUrl: companyConstants.INFO.COOPERATION_URL,
    joinUsUrl: companyConstants.INFO.JOIN_US_URL,
    pmRecruitmentUrl: companyConstants.INFO.PM_RECRUITMENT_URL,

    // banner圖片
    mobileBannerSrc: alternateBanner,
    tabletBannerSrc: alternateBanner,
    desktopBannerSrc: alternateBanner,
  }),

  created() {
    // banner圖片顯示邏輯
    const changeDate = moment('2024-01-07 23:59:59'); // 2024-01-07 23:59:59
    const currentMoment = moment();

    if (currentMoment.isAfter(changeDate)) {
      this.mobileBannerSrc = mobileBanner;
      this.tabletBannerSrc = tabletBanner;
      this.desktopBannerSrc = desktopBanner;
    }
  },

  // for youtube modal
  computed: {
    ...mapState(MODAL, [IS_OPEN]),
    ...mapState(MODAL, [
      PAYLOAD,
    ]),
    youtubeModalIsOpen() {
      return this[IS_OPEN][youtubeModalUUID];
    },
    youtubeUrl() {
      return this[PAYLOAD][youtubeModalUUID];
    },
  },

  watch: {
    'doc.isOpen': (isOpen) => {
      // JR 官網有 modal-open 可以用
      const body = document.querySelector('body');
      isOpen ? body.classList.add('modal-open') : body.classList.remove('modal-open');
      // if (!newValue) this.gallery.picIndex = 0;
    },
    'grade.isOpen': (isOpen) => {
      // JR 官網有 modal-open 可以用
      const body = document.querySelector('body');
      isOpen ? body.classList.add('modal-open') : body.classList.remove('modal-open');
      // if (!newValue) this.gallery.picIndex = 0;
    },
    'reason.isOpen': (isOpen) => {
      // JR 官網有 modal-open 可以用
      const body = document.querySelector('body');
      isOpen ? body.classList.add('modal-open') : body.classList.remove('modal-open');
      // if (!newValue) this.gallery.picIndex = 0;
    },
  },

  mounted() {
    this.bindAnimateElement('[data-scroll]');

    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0% 0% -20% 0%',
    };

    const observer = new IntersectionObserver(this.handleIntersection, observerOptions);
    // 綁定要觸發動畫的元素
    Object.keys(this.$refs).forEach(key => {
      const element = this.$refs[key];
      if (element) {
        observer.observe(element);
      }
    });

    // setTimeout(() => {
    //   this.cleanClass('animate-active');
    // }, 80);
  },

  methods: {
    async submitForm(formdata) {
      const fieldChecked = this.fieldCheck(formdata.parentEmail, formdata.parentPhone, formdata.privacyPolicyCheck, formdata);
      if (fieldChecked) {
        const mediaSource = getEventMediaSource(this.$route.path, this.$route.query);
        const noteInfo = this.getNoteInfo(formdata);
        const params = {
          name: formdata.parentName, // 原為學生姓名，改為家長中文姓名
          phone: formdata.parentPhone, // jr丟入家長的電話
          email: formdata.parentEmail, // jr丟入家長的email
          note: noteInfo, // 其餘資訊總和
          media_source: mediaSource,
        };
        console.log('params', params);
        const result = await lioshutanApi.common.setMediaSource(params);
        if (result.status === httpCodeConstants.SUCCESS) {
          switch (this.$route.query.m) {
            case 'babyview':
              this.$router.push({ name: 'trial-cross-industry-thank-you' });
              break;
            case 'english_education_lecture_babyview':
              this.$router.push({ name: 'trial-cross-industry-thank-you' });
              break;
            default:
              this.$router.push({ name: 'trial-cross-industry-thank-you' }); // 通往感謝頁
              break;
          }
        }
      }
    },

    fieldCheck(email, phone, privacyPolicyCheck, formdata) {
      // let fulfilled = true;
      // Object.values(formdata).forEach((data) => {
      //   if (data === '') {
      //     fulfilled = false;
      //   }
      // });
      // if (!fulfilled) {
      //   alertFailedMessage('似乎還有些欄位尚未填寫完畢');
      //   return false;
      // }

      // 只檢查家長姓名
      if (!formdata.parentName) {
        alertFailedMessage('請留下留下您姓名');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },

    getNoteInfo(formdata) {
      // (iChannel)廠商需要接gid
      // let merchantIChannelsGid = '';
      // const queryGid = this.$route.query.gid;
      // if (queryGid) {
      //   merchantIChannelsGid = `gid=${queryGid} |`;
      // }
      // const noteInfo = `${merchantIChannelsGid} 學員中文姓名: ${formdata.studentChineseName} | 學員英文姓名: ${formdata.studentEnglishName} | 學員年齡: ${formdata.studentAge} | 學員家長姓名: ${formdata.parentName}`;

      let noteInfo = '';

      const queryGid = this.$route.query.gid;
      if (queryGid) {
        noteInfo += `gid=${queryGid} `;
      }

      // 必填
      if (formdata.parentName) {
        noteInfo += `學員家長姓名: ${formdata.parentName}`;
      }
      // 選填
      if (formdata.studentChineseName) {
        noteInfo += ` | 學員中文姓名: ${formdata.studentChineseName}`;
      }
      if (formdata.studentEnglishName) {
        noteInfo += ` | 學員英文姓名: ${formdata.studentEnglishName}`;
      }
      if (formdata.studentAge) {
        noteInfo += ` | 學員年齡: ${formdata.studentAge}`;
      }
      return noteInfo;
    },

    scrollForm(cssSelector) {
      const formLower = document.querySelector(cssSelector);
      formLower.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    // for youtube modal
    youtubeModal(url) {
      this.setIsOpen({
        target: youtubeModalUUID,
        status: !this.youtubeModalIsOpen,
      });

      this.setPayload({
        target: youtubeModalUUID,
        payload: url,
      });
    },

    closeGradeModal(event) {
      if (!event.target.classList.contains('grade-level-btn')) {
        this.level.zero = false;
        this.level.one = false;
        this.level.three = false;
      }
    },

    openGradeModal(levelProp) {
      if (window.innerWidth <= 768) {
        switch (levelProp) {
          case 'zero':
            this.grade.content = this.gradeList[0];
            break;
          case 'one':
            this.grade.content = this.gradeList[1];
            break;
          case 'three':
            this.grade.content = this.gradeList[2];
            break;
          default:
            break;
        }

        this.grade.isOpen = true;
        this.level.zero = false;
        this.level.one = false;
        this.level.three = false;
      } else {
        Object.keys(this.level).forEach(key => {
          if (key === levelProp) {
            this.level[key] = !this.level[key];
          } else {
            this.level[key] = false;
          }
        });
      }
    },

    openReasonModal(letter) {
      switch (letter) {
        case 'a':
          this.reason.content = this.reasonList[0];
          break;
        case 'b':
          this.reason.content = this.reasonList[1];
          break;
        case 'c':
          this.reason.content = this.reasonList[2];
          break;
        case 'd':
          this.reason.content = this.reasonList[3];
          break;
        default:
          break;
      }
      this.reason.isOpen = true;
    },

    bindAnimateElement(cssSelector, refPrefix) {
      const animateList = document.querySelectorAll(cssSelector);
      if (!refPrefix) {
        refPrefix = cssSelector.replace(/\[|\]|#/g, '').replace('.', '').replace(/\./g, '-');
      }
      animateList.forEach((element) => {
        const refName = this.generateRefName(this.refCount, refPrefix);
        this.$refs[refName] = element;
      });
    },

    // cleanClass(className) {
    //   const activeList = document.querySelectorAll(`.${className}`);
    //   activeList.forEach((element) => {
    //     element.classList.remove(className);
    //   });
    // },

    generateRefName(refCount, name) {
      if (!refCount[name]) {
        refCount[name] = 1;
        return `${name}-1`;
      } else {
        const count = refCount[name] + 1;
        refCount[name] = count;
        return `${name}-${count}`;
      }
    },

    handleIntersection(entries) {
      // 遍歷Intersection Observer返回的entry列表
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 如果進入了可視區域，則添加動畫
          // console.log(entry.target);
          entry.target.classList.add('animate-active');
        } else {
          // entry.target.classList.remove('animate-active');
        }
      });
    },
    // for youtube modal
    ...mapMutations(MODAL, [SET_IS_OPEN, SET_PAYLOAD]),
  },
};
</script>
